import { ProfilerQuestion } from './profilers';

export enum SurveyCategory {
  Automotive = 'AUTOMOTIVE',
  Childcare = 'CHILDCARE',
  Education = 'EDUCATION',
  Fashion = 'FASHION',
  FinanceAndBanking = 'FINANCE_AND_BANKING',
  FoodAndDrink = 'FOOD_AND_DRINK',
  General = 'GENERAL',
  Healthcare = 'HEALTHCARE',
  HomeAndGarden = 'HOME_AND_GARDEN',
  LifestyleAndHobbies = 'LIFESTYLE_AND_HOBBIES',
  MediaAndEntertainment = 'MEDIA_AND_ENTERTAINMENT',
  PersonalCareAndBeauty = 'PERSONAL_CARE_AND_BEAUTY',
  Pets = 'PETS',
  Shopping = 'SHOPPING',
  Sports = 'SPORTS',
  Technology = 'TECHNOLOGY',
  ToysAndGames = 'TOYS_AND_GAMES',
  Travel = 'TRAVEL',
  WorkAndBusinessManagement = 'WORK_AND_BUSINESS_MANAGEMENT',
}

export enum SurveyStatus {
  Success = 'SUCCESS',
  Drop = 'DROP',
  NotMatch = 'NOT_A_MATCH',
}

export enum SurveyType {
  Guaranteed = 'GUARANTEED',
  High = 'HIGH_CHANCE',
  Medium = 'MEDIUM_CHANCE',
}

export interface SurveyHistoryItem {
  category: SurveyCategory;
  datetime: string;
  effort: number;
  points: number;
  status: SurveyStatus;
  type: SurveyType;
}

export interface CommitmentOption {
  id: string;
  category: SurveyCategory;
  effort: number;
  points: number;
  size: string;
  type: SurveyType;
  image?: string;
  image_position?: string;
}

export enum CommitmentActionTypes {
  EnterStudy = 'ENTER_STUDY',
  CommitmentDone = 'COMMITMENT_DONE',
  AskProfiler = 'ASK_PROFILER',
  DetachFromCommitment = 'DETACH_FROM_COMMITMENT',
  SupplierCommitmentDone = 'SUPPLIER_COMMITMENT_DONE',
  SupplierDetachFromCommitment = 'SUPPLIER_DETACH_FROM_COMMITMENT',
}

export enum CommitmentDropType {
  NewCommitmentStarted = 'NEW_COMMITMENT_STARTED',
  DropStudyDetected = 'DROP_STUDY_DETECTED',
}

export enum DetachFromCommitmentReason {
  BadSubmission = 'BAD_SUBMISSION',
  NoActiveCommitment = 'NO_ACTIVE_COMMITMENT',
  NotLatestActionOfOngoingCommitment = 'NOT_LATEST_ACTION_OF_ONGOING_COMMITMENT',
}

export interface CommitmentAction<Type extends CommitmentActionTypes> {
  type: Type;
  data: Type extends CommitmentActionTypes.EnterStudy
    ? {
        study_id: number;
        attempt_id: number;
        url: string;
      }
    : Type extends CommitmentActionTypes.AskProfiler
    ? {
        profiler: ProfilerQuestion;
        progress: number;
        seconds_remaining: number;
        skippable: boolean;
      }
    : Type extends CommitmentActionTypes.CommitmentDone
    ? {
        points_earned: number;
        success: boolean;
        drop_type?: CommitmentDropType;
      }
    : Type extends CommitmentActionTypes.SupplierCommitmentDone
    ? {
        return_url: string;
      }
    : Type extends CommitmentActionTypes.SupplierDetachFromCommitment
    ? {
        reason: string;
      }
    : {
        // CommitmentActionTypes.DetachFromCommitment
        reason: DetachFromCommitmentReason;
      };
}

export interface Commitment<
  Type extends CommitmentActionTypes = CommitmentActionTypes
> {
  commitment_id: number;
  action: CommitmentAction<Type>;
}
