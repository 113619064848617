import { APIRoutes } from 'constants/';
import {
  Commitment,
  CommitmentActionTypes,
  CommitmentOption,
  Profiler,
  SurveyHistoryItem,
} from 'types/';
import {
  CommitmentAnswerPayload,
  CommitmentInitiatePayload,
  CommitmentNextActionPayload,
  CommitmentResultPayload,
  ProfilerQuestionPayload,
  SkipAnswerAndNextActionPayload,
  SupplierLogInPayload,
  SupplierSignUpPayload,
  SupplierStatsPayload,
  SupplierStatsQueryParams,
  SupplierTransactionsPayload,
  SupplierTransactionsQueryParams,
  SupplierUrlsParams,
  SupplierUrlsPayload,
} from 'store/api/types/';
import { baseApi, OnboardingTags, SurveyTags, UserTags } from './baseApi';

export const surveysApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    surveyHistory: build.query<SurveyHistoryItem[], void>({
      query: () => ({
        url: APIRoutes.SurveysHistory,
        method: 'GET',
      }),
    }),
    commitmentOptions: build.query<CommitmentOption[], void>({
      query: () => ({
        url: APIRoutes.CommitmentOptions,
        method: 'GET',
      }),
      providesTags: [SurveyTags.Options],
      keepUnusedDataFor: 5,
    }),
    commitmentNextAction: build.mutation<
      Commitment<CommitmentActionTypes>,
      CommitmentNextActionPayload
    >({
      query: (body) => ({
        url: APIRoutes.CommitmentNext,
        method: 'POST',
        body,
      }),
      // transformResponse: (response: Commitment<CommitmentActionTypes>) => {
      //   // Modify the response here
      //   // For example, let's add a timestamp to the response
      //   const resp = {
      //     ...response,
      //     timestamp: new Date().toISOString(),
      //   };
      //   // pass the skippable flag from action to profiler
      //   if ('profiler' in resp.action.data) {
      //     resp.action.data.profiler.skippable = resp.action.data.skippable;
      //   }
      //   return resp;
      // },
      invalidatesTags: [UserTags.UserBasic, UserTags.UserFull],
    }),
    commitmentInitiate: build.mutation<
      Commitment<CommitmentActionTypes>,
      CommitmentInitiatePayload
    >({
      query: (body) => ({
        url: APIRoutes.CommitmentInitiate,
        method: 'POST',
        body,
      }),
      invalidatesTags: [OnboardingTags.UserState, SurveyTags.Options],
    }),
    commitmentSpecialInitiate: build.mutation<
      Commitment<CommitmentActionTypes>,
      CommitmentInitiatePayload
    >({
      query: (body) => ({
        url: APIRoutes.CommitmentInitiateSpecial,
        method: 'POST',
        body,
      }),
      invalidatesTags: [OnboardingTags.UserState],
    }),
    commitmentDummyData: build.query<void, void>({
      query: () => ({
        url: APIRoutes.TEMP_CommitmentDummyData,
        method: 'GET',
      }),
    }),
    commitmentResult: build.mutation<void, CommitmentResultPayload>({
      query: (body) => ({
        url: APIRoutes.CommitmentResult,
        method: 'POST',
        body,
      }),
    }),
    commitmentAnswer: build.mutation<
      Commitment<CommitmentActionTypes>,
      CommitmentAnswerPayload
    >({
      query: (body) => ({
        url: APIRoutes.CommitmentSubmitAnswer,
        method: 'POST',
        body,
      }),
    }),
    commitmentAnswerAndNextAction: build.mutation<
      Commitment<CommitmentActionTypes>,
      CommitmentResultPayload
    >({
      query: (body) => ({
        url: APIRoutes.CommitmentSubmitAnswerAndNextAction,
        method: 'POST',
        body,
      }),
    }),
    getProfilerQuestion: build.query<Profiler, ProfilerQuestionPayload | void>({
      query: (body) => ({
        url: `${APIRoutes.TEMP_Profiler_Data}${
          body?.id ? `?id=${body.id}` : ''
        }`,
        method: 'GET',
      }),
    }),
    supplierLogIn: build.mutation<
      Commitment<CommitmentActionTypes>,
      SupplierLogInPayload
    >({
      query: (body) => ({
        url: APIRoutes.SupplierLogIn,
        method: 'POST',
        body,
      }),
    }),
    supplierSignUp: build.mutation<
      Commitment<CommitmentActionTypes>,
      SupplierSignUpPayload
    >({
      query: (body) => ({
        url: APIRoutes.SupplierSignUp,
        method: 'POST',
        body,
      }),
    }),
    supplierStats: build.query<
      SupplierStatsPayload,
      SupplierStatsQueryParams | void
    >({
      query: (body) => {
        const {
          from,
          until,
          date_grouping,
          country_codes,
          country_grouping,
          page,
          page_size,
        } = body || {};

        const buildQueryParam = (
          param: string,
          value?: string | string[] | boolean | number
        ) => {
          if (typeof value === 'boolean') {
            return `${param}=${value}`;
          }
          return value
            ? `${param}=${Array.isArray(value) ? value.join(',') : value}`
            : '';
        };

        const params = [
          buildQueryParam('from', from),
          buildQueryParam('until', until),
          buildQueryParam('date_grouping', date_grouping),
          buildQueryParam('country_grouping', country_grouping),
          buildQueryParam('country_codes', country_codes),
          buildQueryParam('page', page),
          buildQueryParam('page_size', page_size),
        ]
          .filter(Boolean)
          .join('&');

        return {
          url: `${APIRoutes.SupplierStats}${params ? `?${params}` : ''}`,
          method: 'GET',
        };
      },
    }),

    supplierTransactions: build.query<
      SupplierTransactionsPayload,
      SupplierTransactionsQueryParams | void
    >({
      query: (body) => {
        const { from, until, country_codes, mid, pid, page, page_size } =
          body || {};

        const buildQueryParam = (
          param: string,
          value?: string | string[] | number
        ) =>
          value
            ? `${param}=${Array.isArray(value) ? value.join(',') : value}`
            : '';

        const params = [
          buildQueryParam('from', from),
          buildQueryParam('until', until),
          buildQueryParam('country_codes', country_codes),
          buildQueryParam('mid', mid),
          buildQueryParam('pid', pid),
          buildQueryParam('page', page),
          buildQueryParam('page_size', page_size),
        ]
          .filter(Boolean)
          .join('&');

        return {
          url: `${APIRoutes.SupplierTransactions}${params ? `?${params}` : ''}`,
          method: 'GET',
        };
      },
    }),
    getSupplierUrls: build.query<SupplierUrlsParams, void>({
      query: () => ({
        url: APIRoutes.SupplierUrls,
        method: 'GET',
      }),
    }),
    updateSupplierUrls: build.mutation<void, SupplierUrlsPayload>({
      query: (body) => ({
        url: APIRoutes.SupplierUrls,
        method: 'PATCH',
        body,
      }),
    }),

    commitmentSkipAnswerAndNextAction: build.mutation<
      Commitment<CommitmentActionTypes>,
      SkipAnswerAndNextActionPayload
    >({
      query: (body) => ({
        url: APIRoutes.CommitmentSkipAnswerAndNextAction,
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useSurveyHistoryQuery,
  useCommitmentDummyDataQuery,
  useCommitmentInitiateMutation,
  useCommitmentSpecialInitiateMutation,
  useCommitmentNextActionMutation,
  useCommitmentOptionsQuery,
  useCommitmentResultMutation,
  useCommitmentAnswerMutation,
  useCommitmentAnswerAndNextActionMutation,
  useSupplierLogInMutation,
  useSupplierSignUpMutation,
  useSupplierStatsQuery,
  useSupplierTransactionsQuery,
  useLazyCommitmentDummyDataQuery,
  useLazyGetProfilerQuestionQuery,
  useGetSupplierUrlsQuery,
  useUpdateSupplierUrlsMutation,
  useCommitmentSkipAnswerAndNextActionMutation,
} = surveysApi;
