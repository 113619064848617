import { APIRoutes } from 'constants/';
import { baseApi } from './baseApi';

// Define an interface for the wall data response
export interface WallDataResponse {
  // Add specific properties expected in the response
  // For example:
  // surveys: Survey[];
  // rewards: Reward[];
  // Add more properties as needed
  [key: string]: any; // This allows for additional properties
}

// Define an interface for the query parameters
export interface WallDataQueryParams {
  [key: string]: string | number | boolean | undefined | null;
}

export const wallApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getWallData: build.query<WallDataResponse, WallDataQueryParams>({
      query: (params) => ({
        url: APIRoutes.Wall,
        method: 'GET',
        params,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetWallDataQuery } = wallApi;
