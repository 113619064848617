import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { t } from '@lingui/macro';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { EventIds, Routes } from 'constants/';
import {
  openGenericErrorNotification,
  openInfoNotification,
  openSuccessNotification,
  openWarningNotification,
} from 'helpers/';
import {
  Commitment,
  CommitmentActionTypes,
  CommitmentDropType,
  DetachFromCommitmentReason,
  OnboardingState,
  SurveysState,
} from 'types/';
import {
  useCurrentUserFullInfoQuery,
  useCurrentUserQuery,
  useGetUserStateQuery,
  useLazyGetUserStateQuery,
} from 'store/api';
import { log } from 'utils/logService';

export const useNextCommitmentAction = <
  Type extends CommitmentActionTypes,
  T extends Commitment<Type>
>() => {
  const history = useHistory();

  const { refetch: refetchOnboardingState } = useGetUserStateQuery();
  const { refetch: refetchUser } = useCurrentUserQuery();
  const { refetch: refetchFullUser } = useCurrentUserFullInfoQuery();
  const [getUserState] = useLazyGetUserStateQuery();
  return useCallback(
    (
      result: { data: T } | { error: FetchBaseQueryError | SerializedError }
    ) => {
      if (
        'data' in result &&
        result.data.action.type === CommitmentActionTypes.AskProfiler
      ) {
        // ensure skippable flag gets passed from action to profiler
        if (
          'profiler' in result.data.action.data &&
          'skippable' in result.data.action.data
        ) {
          result = {
            ...result,
            data: {
              ...result.data,
              action: {
                ...result.data.action,
                data: {
                  ...result.data.action.data,
                  profiler: {
                    ...result.data.action.data.profiler,
                    skippable: result.data.action.data.skippable,
                  },
                },
              },
            },
          };
        }
      }

      // if (
      //   'data' in result &&
      //   result.data.action.type === CommitmentActionTypes.AskProfiler
      // ) {
      //   result.data.action.profiler.data.skippable = result.data.skippable;
      // }
      console.log('Next commitment action', result);

      const { data } = result as { data: T };

      if (data.action.type === CommitmentActionTypes.AskProfiler) {
        history.push(Routes.Profilers, data);
        return;
      }

      if (data.action.type === CommitmentActionTypes.EnterStudy) {
        window.location.href = (
          data as Commitment<CommitmentActionTypes.EnterStudy>
        ).action.data.url;
        return;
      }

      if (data.action.type === CommitmentActionTypes.SupplierCommitmentDone) {
        window.location.href = (
          data as Commitment<CommitmentActionTypes.SupplierCommitmentDone>
        ).action.data.return_url;
        return;
      }

      if (
        data.action.type === CommitmentActionTypes.SupplierDetachFromCommitment
      ) {
        history.push(Routes.ErrorPage, {
          title_alt: t`Are you using multiple tabs?`,
          description_alt: t`It looks like you're returning to our site without finishing your last survey, or starting a new one from your survey app. This usually happens when you are copying and pasting urls or opening up new tabs in the middle of a survey. If you still have the survey open in another tab, you can finish taking the survey there.`,
          hide_button: true,
        });
        return;
      }

      refetchUser();
      refetchFullUser();

      getUserState().then((result) => {
        const { error } = result as { error: SerializedError };
        if (error) {
          log.error(
            'Query to get user onboarding state has failed',
            EventIds.OnboardingStateQueryError,
            error
          );
          openGenericErrorNotification();
          history.push(Routes.Dashboard);
          return;
        }

        const { onboard_service, onboard_surveys } = result.data || {};

        if (
          onboard_service?.state ===
            OnboardingState.ONBOARD_SERVICE_FLOW_01_STAGE_02 &&
          onboard_service.show_popup
        ) {
          refetchOnboardingState();
          history.push(Routes.Dashboard);
          return;
        }

        if (
          onboard_surveys?.state &&
          [
            SurveysState.ONBOARD_SURVEYS_FLOW_01_STAGE_03,
            SurveysState.ONBOARD_SURVEYS_FLOW_01_STAGE_04,
          ].includes(onboard_surveys.state) &&
          onboard_surveys.show_popup
        ) {
          refetchOnboardingState();
          history.push(Routes.Dashboard);
          return;
        }

        // Handle situations where we need to detach from the commitment.
        // This happens when user submits a response to a commitment action
        // which isn't actually the latest action in user's ongoing commitment.
        if (data.action.type === CommitmentActionTypes.DetachFromCommitment) {
          const { reason } = (
            data as Commitment<CommitmentActionTypes.DetachFromCommitment>
          ).action.data;

          if (
            reason === DetachFromCommitmentReason.NoActiveCommitment ||
            reason ===
              DetachFromCommitmentReason.NotLatestActionOfOngoingCommitment
          ) {
            openWarningNotification(
              t`It looks like you're taking this survey in multiple tabs. You can finish your survey in the original tab, or abandon it and start a new one.`,
              undefined,
              15
            );
          } else {
            // Covers: `DetachFromCommitmentReason.BadSubmission`
            openGenericErrorNotification();
          }

          history.push(Routes.Dashboard);
          return;
        }

        const {
          success,
          drop_type,
          points_earned: numberOfPointsEarned,
        } = (data as Commitment<CommitmentActionTypes.CommitmentDone>).action
          .data;

        if (success && numberOfPointsEarned > 0) {
          openSuccessNotification(
            t`Congratulations! You earned ${numberOfPointsEarned} points.`
          );
        } else if (success && numberOfPointsEarned === 0) {
          openInfoNotification(
            t`Sorry, you didn't earn any points this time. Challenge surveys have about a 1 in 3 chance of paying out, and Moonshot have about a 1 in 20 chance. Try your luck again with Challenge or Moonshot, or earn surefire points with Guaranteed surveys.`,
            undefined,
            15
          );
        } else if (drop_type === CommitmentDropType.NewCommitmentStarted) {
          // commitment closed because new one was started
          openInfoNotification(
            t`Survey terminated: You can only take one survey at a time. Starting a new survey terminates the old one.`,
            undefined,
            15
          );
        } else if (drop_type === CommitmentDropType.DropStudyDetected) {
          // commitment closed because study drop was detected
          openInfoNotification(
            t`Survey terminated: Please answer all of the questions in the order provided and refrain from hitting the back button.`,
            undefined,
            15
          );
        } else {
          // commitment not completed - exact reason not known (likely - no studies were available at the time)
          openInfoNotification(
            t`Sorry, you aren't a match for this survey. Take another survey and try your luck again.`
          );
        }

        history.push(Routes.Dashboard);
      });
    },
    []
  );
};
